import Arrow from 'components/Icons/Arrow';
import styled, { css } from 'styled-components';
import { motion } from 'framer-motion';

import { Description } from 'components/ErrorPages/styled';

import { mediaDesktop, mediaTablet } from 'utils/responsive';

export const LinkWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  margin-top: 7rem;
  cursor: pointer;
  ${mediaDesktop(css`
    top: 90%;
  `)}
`;

export const HomeLink = styled(motion.a)`
  user-select: none;
  font-family: 'Arapey', sans-serif;
  font-style: italic;
  font-size: 1.2rem;
  color: ${({ theme }) => theme.colors.primary.dark};
  text-decoration: none;
  ${mediaTablet(css`
    font-size: 1.5rem;
  `)}
`;

export const NotFoundDescription = styled(Description)`
  width: 17rem;
  ${mediaTablet(css`
    width: 40rem;
  `)}
`;

export const GoBackArrow = styled(Arrow)`
  height: auto;
  width: 11vh;
  transform: rotate(180deg);
`;
