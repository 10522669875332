import * as React from 'react';
import { useState } from 'react';
import Link from 'next/link';
import { GetStaticProps } from 'next';

import Separator from 'components/Icons/Separator';
import HeaderLogo from 'components/FilmPage/HeaderLogo';

import { ErrorType } from 'types/error';
import { ERROR_TYPES } from 'constants/errors';

import { cmsApiClient } from 'utils/api/cms';
import { notFoundQuery } from 'utils/gql';
import { getErrorByType } from 'utils/errors';
import theme from 'utils/theme';

import Head from 'u9/components/Head';

import {
  ArrowWrapper,
  Container,
  ContentWrapper,
  StyledSeparator,
  Title,
  Wrapper,
} from '../styled';
import {
  GoBackArrow,
  HomeLink,
  LinkWrapper,
  NotFoundDescription,
} from './styled';

export const getStaticProps: GetStaticProps = async () =>
  Promise.all([cmsApiClient.query({ query: notFoundQuery() })]).then(
    ([errorsResults]) => ({
      props: {
        errors: errorsResults.data.errorPages,
      },
    })
  );

export type NotFoundScreenProps = {
  errors: ErrorType[];
};

const NotFoundScreen = ({ errors }: NotFoundScreenProps): JSX.Element => {
  const currentError = getErrorByType(errors, ERROR_TYPES.NOT_FOUND);
  const description = currentError.description.replace('.', '.`').split('`');

  const [isHovered, setHover] = useState(false);

  const arrowAnimation = {
    variants: {
      hover: { x: 24 },
      initial: { x: 0 },
    },
    animate: isHovered ? 'hover' : 'initial',
  };

  return (
    <Container>
      <Head {...currentError.head} />
      <Wrapper>
        <HeaderLogo color={theme.colors.primary.dark} />
        <ContentWrapper>
          <Title>{currentError.title}</Title>
          <NotFoundDescription>
            <div>{description[0]}</div>
            <div>{description[1]}</div>
          </NotFoundDescription>
          <StyledSeparator />
          <LinkWrapper>
            <Link href={'/'}>
              <HomeLink
                onHoverStart={() => setHover(true)}
                onHoverEnd={() => setHover(false)}
              >
                {currentError.instruction}
              </HomeLink>
            </Link>
            <ArrowWrapper
              animate={arrowAnimation.animate}
              variants={arrowAnimation.variants}
            >
              <GoBackArrow />
            </ArrowWrapper>
          </LinkWrapper>
        </ContentWrapper>
      </Wrapper>
    </Container>
  );
};

NotFoundScreen.displayName = 'NotFoundScreen';

export default NotFoundScreen;
